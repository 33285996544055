.stickyTable {
    overflow: auto;
    max-height: 375px;
}

.stickyHeader {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}